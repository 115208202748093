import styled from 'styled-components'

export const ExpandablePanelComponent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.primary4};
`

export const Label = styled.span`
  display: flex;
  align-items: center;

  outline: none;
  cursor: pointer;
  height: 26px;

  margin-right: auto;
`
export const Content = styled.div`
  font-size: 12px;
`
