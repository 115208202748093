import React from 'react'
import styled from 'styled-components'

const BlockHeaderStyle = styled.div<{ marginBottom?: string }>`
  margin-bottom: ${({ marginBottom }) => marginBottom || '20px'};

  display: flex;
  justify-content: ${({ theme }) => (theme.mode === 'metis' ? 'center' : 'space-between')};
`

export function BlockHeader(props: { children: React.ReactNode; marginBottom?: string }) {
  const { children, ...rest } = props
  return <BlockHeaderStyle {...rest}>{children}</BlockHeaderStyle>
}
