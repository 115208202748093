export const white = '#FFFFFF'
export const black = '#000000'
export const light = '#FDF4E1'
export const dark = '#141414'
export const orange = '#F3BA2F'
export const blue = '#51A8E9'
export const red = '#E85A44'
export const grey1 = '#D1D1CF'
export const grey2 = '#999999'
export const green = '#42CE61'
