import { Text } from 'rebass'
import styled from 'styled-components'

const TextWrapper = styled(Text)<{ color?: string }>`
  font-family: ${({ theme }) => theme.typography.fontFamily.secondary};
  color: ${({ color, theme }) => color || theme.colors.text1};
`

export const TextBase = styled(TextWrapper)<{ color?: string; fontWeight?: string }>`
  color: ${({ color, theme }) => color || theme.colors.text1};
  font-family: ${({ theme }) => theme.typography.fontFamily.secondary};

  font-weight: ${({ theme, fontWeight }) => (theme.mode === 'metis' ? '400' : fontWeight || '500')};
  font-size: ${({ theme }) => (theme.mode === 'metis' || theme.mode === 'dark' ? '14px' : '16px')};
  line-height: ${({ theme }) => (theme.mode === 'metis' ? '19px' : theme.mode === 'dark' ? '14px' : '16px')};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: ${({ theme }) => (theme.mode === 'metis' ? '14px' : '14px')};
    line-height: ${({ theme }) => (theme.mode === 'metis' ? '19px' : '14px')};
	`};
`

export const TextBaseLg = styled(TextWrapper)<{ color?: string; fontWeight?: string }>`
  color: ${({ color, theme }) => color || theme.colors.text1};
  font-family: ${({ theme }) => theme.typography.fontFamily.secondary};

  font-weight: ${({ fontWeight }) => fontWeight || '500'};
  font-size: 18px;
  line-height: 18px;

  ${({ theme }) =>
    theme.mode === 'metis' &&
    `
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  `}

  ${({ theme }) =>
    theme.mode === 'dark' &&
    `
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  `}

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: ${({ theme }) => (theme.mode === 'metis' || theme.mode === 'dark' ? '14px' : '16px')};
		line-height: 16px;
	`};
`

export const TextBaseMetisLg = styled(TextBaseLg)<{ color?: string; fontWeight?: string }>`
  font-weight: ${() => '900'};
`

export const TextBaseXl = styled(TextWrapper)<{ color?: string; fontWeight?: string; wrapText?: boolean }>`
  color: ${({ color, theme }) => color || theme.colors.text1};
  font-weight: ${({ theme, fontWeight }) => (theme.mode === 'metis' ? '400' : fontWeight || '500')};
  font-size: ${({ theme }) => (theme.mode === 'metis' ? '14px' : '20px')};
  line-height: ${({ theme }) => (theme.mode === 'metis' ? '19px' : '20px')};

  ${({ wrapText }) =>
    wrapText &&
    `
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 18px;
    line-height: 18px;
  `};
`

export const TextBaseH3 = styled(TextWrapper)<{ color?: string }>`
  margin-bottom: 10px !important;

  color: ${({ color, theme }) => color || theme.colors.text1};
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
`

export const TextBaseWithoutChanges = styled(TextWrapper)<{ color?: string }>`
  margin-bottom: 15px !important;

  color: ${({ color, theme }) => color || theme.colors.text1};
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  &:last-child {
    margin-bottom: 0 !important;
  }
`
