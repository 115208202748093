import React from 'react'
import useCollapse from 'react-collapsed'
import { Text } from 'rebass'

import { ExpandablePanelComponent, Label, Content } from './styleds'

type ExpandablePanelProps = {
  label: string
  children: React.ReactNode
  style?: React.CSSProperties
}

export default function ExpandablePanel({ label, style, children }: ExpandablePanelProps) {
  const { isExpanded, getToggleProps, getCollapseProps } = useCollapse()

  return (
    <ExpandablePanelComponent style={style}>
      <Label {...getToggleProps()}>
        {isExpanded ? <Text fontSize={3}>▼</Text> : <Text>▶</Text>}
        &nbsp;
        {label}
      </Label>
      <Content {...getCollapseProps()}>{children}</Content>
    </ExpandablePanelComponent>
  )
}
