import React from 'react'

import { useDynamicLoadImage } from 'hooks/useDynamicLoadImage'

type DynamicImageProps = {
  imageName: string
  alt: string
}

export default function DynamicImage({ imageName, ...props }: DynamicImageProps) {
  const image = useDynamicLoadImage(imageName)

  return <img src={image} {...props} />
}
