import React from 'react'
import styled, { css, DefaultTheme, StyledComponent } from 'styled-components'

type Heading = 'h1' | 'h2' | 'h3' | 'h4' | 'h5'

type Props = {
  as?: Heading
  size?: 'lg' | 'md'
}

const titleCommonStyles = css<{ size: 'lg' | 'md' }>`
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-weight: normal;
  font-size: 20px;
  line-height: ${({ size }) => (size === 'md' ? '140%' : '28px')};
  margin: 0;

  ${({ theme, size }) =>
    size === 'lg'
      ? theme.mediaWidth.upToSmall`
    font-size: 20px;
    line-height: 28px;
  `
      : ''};
`

const titleMetisStyles = css<{ size: 'lg' | 'md' }>`
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-weight: 900;
  font-size: ${({ size }) => (size === 'lg' ? '20px' : '16px')};
  line-height: ${({ size }) => (size === 'lg' ? '28px' : '22px')};
  margin: 0;

  ${({ theme, size }) =>
    size === 'lg'
      ? theme.mediaWidth.upToSmall`
    font-size: 20px;
    line-height: 28px;
  `
      : ''};
`

const titleProStyles = css<{ size: 'lg' | 'md' }>`
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-weight: 400;
  font-size: 20px;
  text-transform: uppercase;
  margin: 0;
`

const titleStyles = css<{ size: 'lg' | 'md' }>`
  ${({ theme }) =>
    theme.mode === 'metis' ? titleMetisStyles : theme.mode === 'dark' ? titleProStyles : titleCommonStyles}
`

const heading: Record<Heading, StyledComponent<Heading, DefaultTheme, { size: 'lg' | 'md' }>> = {
  h1: styled.h1`
    ${titleStyles}
  `,
  h2: styled.h2`
    ${titleStyles}
  `,
  h3: styled.h3`
    ${titleStyles}
  `,
  h4: styled.h4`
    ${titleStyles}
  `,
  h5: styled.h5`
    ${titleStyles}
  `
}

export const Title: React.FC<Props> = ({ as = 'h2', size = 'md', children }) => {
  const H = heading[as]

  return <H size={size}>{children}</H>
}
