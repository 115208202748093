import styled from 'styled-components'

export const ExpandableStackError = styled.p`
  display: flex;
  flex-wrap: wrap;

  height: 20em;
  overflow-x: auto;
  word-break: break-word;

  margin: 0;
  padding: 0 5px 0 20px;
`
