import styled from 'styled-components'

export const UpperSection = styled.div`
  position: relative;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 700;
  }
`

export const UpperSectionClose = styled.div`
  position: absolute;
  top: -22px;
  right: -22px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: -5px;
    right: -3px;
  `};
`

export const HeaderRow = styled.div`
  margin-bottom: 22px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-bottom: 12px;
  `};
`

export const HeaderRowJustify = styled(HeaderRow)`
  justify-content: ${({ theme }) => (theme.mode === 'metis' ? 'center' : 'space-between')};
`

export const ContentWrapper = styled.div``
