// Firebase Cloud Messaging Configuration File.
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import { initializeApp } from 'firebase/app'
import { isSupported, getMessaging, getToken, deleteToken, onMessage } from 'firebase/messaging'

import { vapidKey, firebaseConfig } from './config'

// Initialize Firebase
initializeApp(firebaseConfig)

export const FirebasPushNotificationsService = {
  isSupported: (): Promise<boolean> => {
    return isSupported()
  },

  getNotificationToken: (): Promise<string | void | undefined> => {
    const messaging = getMessaging()

    return getToken(messaging, { vapidKey })
      .then(currentToken => {
        if (!currentToken) {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.')
        }

        return currentToken
      })
      .catch(err => {
        console.error('An error occurred while retrieving token. ', err)
      })
  },

  deleteNotificationToken: (): Promise<boolean> => {
    const messaging = getMessaging()

    return deleteToken(messaging)
  },

  onNotificationListener: () => {
    const messaging = getMessaging()

    return onMessage(messaging, payload => {
      const title = 'WoWSwap notification'
      const notificationOptions: NotificationOptions = {
        body: payload?.data?.message,
        icon: '/images/192x192_App_Icon.png'
      }
      new Notification(title, notificationOptions)
    })
  }
}
