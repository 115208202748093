import styled, { css } from 'styled-components'

import { AutoColumn } from 'components/Column'
import { Spinner } from 'components/Shared'

export const Wrapper = styled.div`
  width: 100%;
`
export const Section = styled(AutoColumn)``

export const BottomSection = styled(Section)`
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`

export const Title = styled.div`
  font-size: 18px;
  font-weight: 700;

  ${({ theme }) =>
    theme.mode === 'dark' &&
    css`
      font-weight: 400;
      text-transform: uppercase;
    `}
`

export const Message = styled.div`
  text-align: center;
  width: 85%;
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary4};

  ${({ theme }) =>
    theme.mode === 'dark' &&
    css`
      font-weight: 400;
      color: ${({ theme }) => theme.colors.white};
    `}
`

export const StyledLogo = styled.img`
  height: 16px;
  width: 16px;
  margin-left: 6px;
`

export const CustomLightSpinner = styled(Spinner)<{ size: string }>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`

export const WraperClose = styled.div`
  position: absolute;
  top: 23px;
  right: 23px;

  ${({ theme }) =>
    theme.mode === 'metis' &&
    css`
      top: 34px;
      right: 35px;
    `}

  ${({ theme }) =>
    theme.mode === 'dark' &&
    css`
      top: 35px;
      right: 35px;
    `}

  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: ${({ theme }) => (theme.mode === 'metis' ? '15px' : '23px')};
    right: ${({ theme }) => (theme.mode === 'metis' ? '20px' : '23px')};
  `};
`
